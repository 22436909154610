body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
}

@media (max-width: 450px) {
  body {
    font-size: 2.8vw;
  }
}
